/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

 (function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        // Mobile Menu
        $('.navToggle').click(function(){
          $('.mobile-menu').toggleClass('menuOn');
          $('nav').toggleClass('navOn');
        });

        // Fjern er <spans> fra contact-forms
        var fixedSpans = false;
        if (fixedSpans === false) {
          $('.hasUnWantedSpan').each(function() {
            $(this).unwrap();
          });
          fixedSpans = true;
        }

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
      // Support page
      'support': {
        init: function() {
          // JavaScript to be fired on the support page
          
          // Accordion collapse
          $('.closeall').click(function(){
            $('.collapse.show')
            .collapse('hide');
          });
          $('.openall').click(function(){
            $('.collapse:not(".show")')
            .collapse('show');
          });
        }
      },
      'woocommerce_edit_account': {
        init: function() {
          // get the value from the costum-avatar shown in the side-bar and trim it to get the direct path       
          var avatar = $('.avatar').css('background-image');
          avatar = avatar.replace('url("','').replace(')")','');
          
          // if there is an avatar - assign the value to the hidden costum-avatar field
          if (avatar !== "none") {
            $('#acf-field_5a954c447caa5').val(avatar);
          }
        }
      },
      'bestil_brochure': {
        init: function() {
          // JavaScript to be fired on the bestil-brochure page
          
          // $("#field_11_12").addClass("activePage");

          $(".disabled").click(function(event){
            event.preventDefault();
          }); 

          var tabCount = 1;
          var tabState = 1;

          $('.gravity-forms-section-tabs-head').find('a').each(function() {
            $(this).attr('id', tabCount);
            tabCount++;
          });

          $('.gravity-forms-section-tabs-head').find('a').click(function() {
            tabState = $(this).attr('id');
          });

          function navPage ( tabState ) {
            if (state !== 5) {
              $(".btnNext").show();
            }
          }

          $(".btnNext").click(function(event) {
            tabState++;
            $('.gravity-forms-section-tabs-head').find('#'+tabState).click();
            $(window).scrollTop(0);
            event.preventDefault();
          });

          // lokal id

          $("#input_1_39 input[type='radio']").click(function(event){
            input_1_149(event)
          });
          function input_1_149(event) {
         };
         $("#input_1_40 input[type='radio']").click(function(event) {
          input_1_154(event)
        });
         function input_1_154(event) {
         };

         $("#delImgTempC").click(function(event){
          event.preventDefault();
          input_1_124(event)
        });
         $("#input_1_41 input[type='radio']").click(function(event) {
          input_1_124(event)
        });
         function input_1_124(event) {
           $('#input_1_124 input[type="checkbox"]').prop('checked', false);
         };
         $("#delImgTempD").click(function(event){
          event.preventDefault();
          input_1_125(event)
        });
         $("#input_1_43 input[type='radio']").click(function(event) {
          input_1_125(event)
        });
         function input_1_125(event) {
           $('#input_1_125 input[type="checkbox"]').prop('checked', false);
         };  
         $("#delImgTempE").click(function(event){
          event.preventDefault();
          input_1_132(event)
        });
         $("#input_1_43 input[type='radio']").click(function(event) {
          input_1_132(event)
        });
         function input_1_132(event) {
           $('#input_1_132 input[type="checkbox"]').prop('checked', false);
         };  


         $(function () {
          $("#gform_submit_button_1").addClass("btn btn-primary");
        });

         $(".genSelector").find('input[type=checkbox]').change(function(){
          if ($(".genSelector").find('input[type=checkbox]:checked').length > 2){
            $("#field_1_156").hide();
            $("#field_1_165").show();
          }else{
            $("#field_1_165").hide();
            $("#field_1_156").show();
          }

        });

          //wp engine id - form id 1
          $("#input_3_39 input[type='radio']").click(function(event){
            input_3_122(event)
          });
          $("#delImgTempA").click(function(event){
            event.preventDefault();
            input_3_122(event)
          });

          function input_3_122(event) {
           $('#input_3_122 input[type="checkbox"]').prop('checked', false);
         };
         $("#input_3_40 input[type='radio']").click(function(event) {
          input_3_123(event)
        });
         $("#delImgTempB").click(function(event){
          event.preventDefault();
          input_3_123(event)
        });
         function input_3_123(event) {
           $('#input_3_123 input[type="checkbox"]').prop('checked', false);
         };

         $("#delImgTempC").click(function(event){
          event.preventDefault();
          input_3_124(event)
        });
         $("#input_3_41 input[type='radio']").click(function(event) {
          input_3_124(event)
        });
         function input_3_124(event) {
           $('#input_3_124 input[type="checkbox"]').prop('checked', false);
         };
         $("#delImgTempD").click(function(event){
          event.preventDefault();
          input_3_125(event)
        });
         $("#input_3_43 input[type='radio']").click(function(event) {
          input_3_125(event)
        });
         function input_3_125(event) {
           $('#input_3_125 input[type="checkbox"]').prop('checked', false);
         };  
         $("#delImgTempE").click(function(event){
          event.preventDefault();
          input_3_132(event)
        });
         $("#input_3_43 input[type='radio']").click(function(event) {
          input_3_132(event)
        });
         function input_3_132(event) {
           $('#input_3_132 input[type="checkbox"]').prop('checked', false);
         };  


         $(function () {
          $("#gform_submit_button_3").addClass("btn btn-primary");
        });


         $(".genSelector").find('input[type=checkbox]').change(function(){
          if ($(".genSelector").find('input[type=checkbox]:checked').length > 2){
            $("#field_3_156").hide();
            $("#field_3_165").show();
          }else{
            $("#field_3_165").hide();
            $("#field_3_156").show();
          }

        });

         function readURL(input) {
          if (input.files && input.files[0]) {
           var reader = new FileReader();
           reader.onload = function (e) {
            console.log(e.target.result);
            switch(input.id) {

              //////////// lokal ids
              case "input_1_149":
              $('#input_1_39 input[type="radio"]:checked').each(function(i) {
               this.checked = false;
             });
              break;

              case "input_1_154":
              $('#input_1_40 input[type="radio"]:checked').each(function(i) {
               this.checked = false;
             });
              break;

              case "input_1_155":
              $('#input_1_41 input[type="radio"]:checked').each(function(i) {
               this.checked = false;
             });
              break;

              case "input_1_156":
              case "input_1_165":
              $('#input_1_43 input[type="radio"]:checked').each(function(i) {
               this.checked = false;
             });
              break;

                ////////// WP egine ids  - form ID 1
                case "input_3_149":
                $('#input_3_39 input[type="radio"]:checked').each(function(i) {
                 this.checked = false;
               });
                break;

                case "input_3_154":
                $('#input_3_40 input[type="radio"]:checked').each(function(i) {
                 this.checked = false;
               });
                break;

                case "input_3_155":
                $('#input_3_41 input[type="radio"]:checked').each(function(i) {
                 this.checked = false;
               });
                break;

                case "input_3_156":
                case "input_3_165":
                $('#input_3_43 input[type="radio"]:checked').each(function(i) {
                 this.checked = false;
               });
                break;


                default:
                // nothing
              }
            };

            reader.readAsDataURL(input.files[0]);
          }
        }

        $(".uploadOwnImg input").change(function(){
         readURL(this);
       });  

        $('#gform_submit_button_3').click (function (e) {
           // setTimeout(function () {
           //   window.location.href = "/checkout";
           //  }, 0); //0 sec

       });     

      }
    },
      // Create website page
      'opret_website': {
        init: function() {
          // JavaScript to be fired on the opret-website page

          // utilizing the revealing module pattern to make variables local
          var acfController = (function () {

            var acfSubmit = ".acf-form-submit";
            var acf = ".acf-field-";

            // setting all acf's on their id
            var acf0 = acf + "5a72f344ea880"; // farm description
                var acf1 = acf + "5a72e4d58c5e0"; // farm name
                var acf1x = acf + "5a954999f263c"; // farm lead title name
                var acf2 = acf + "5a3ba5978f0d1"; // about
                var acf2x = acf + "5a8d4b6dfa6ae"; // about - TIP
                var acf3 = acf + "5a72ed152d7ef"; // results
                var acf3x = acf + "5a8d50868fc05"; // results - TIP

            var acf4x0 = acf + "5a8ad2ffcd30b"; // genes description
            var acf4x1 = acf + "5a72e76a8c5e1"; // genes
                var pig1 = acf + "5a72eb83569fc"; // danbred landrace
                var pig1x = acf + "5a940af03503e"; // danbred landrace - TIP
                var pig2 = acf + "5a72ebff569fd"; // danbred yorkshire
                var pig2x = acf + "5a940db4a4bd5"; // danbred yorkshire - TIP
                var pig3 = acf + "5a72ec0f569fe"; // danbred duroc
                var pig3x = acf + "5a940df4a4bd6"; // danbred duroc - TIP
                var pig4 = acf + "5a72ec1f569ff"; // danbred hybrid
                var pig4x = acf + "5a940df9a4bd7"; // danbred hybrid - TIP

            var acf5x0 = acf + "5a8ad363cd30c"; // image description
            var acf5x1 = acf + "5a72ee562d7f4"; // image (only used for the functionality - should never be displayed)
                var hiddenImage      = acf + "5a7d658a5cda0";   // used for controlling the image being chosen
                var imagePreview     = "#previewUploadedImage"; // used for controlling the image being uploaded

            var acf6x0 = acf + "5a8ad38ccd30d"; // contact info description
                var acf6 = acf + "5a72ed662d7f0"; // email
                var acf7 = acf + "5a72ee192d7f1"; // name
                var acf8 = acf + "5a72ee252d7f2"; // phone
                var acf8x = acf + "5a8ed64eb5069"; // person repeater
                var acf8y = acf + "5a8d932ab8fbe"; // healthcheck
                var acf8z = acf + "5a8e80cd3a580"; // address

            var acf9 = acf + "5a8ad3fccd30e"; // last-tjeck
            var endContet = "#lastTjekContent"; // used for controlling the submit of the form

            // dividing into groups of arrays according to titles on the page
            var aboutTheFarm = [acf0, acf1, acf1x, acf2, acf3, acf2x, acf3x];       // (1)
            var geneSelector = [acf4x0, acf4x1];                                    // (2)
                var geneArray  = [pig1, pig2, pig3, pig4, pig1x, pig2x, pig3x, pig4x];
            var imageSelector = [acf5x0, imagePreview];                             // (3)
            var contantInfo = [acf6x0, acf6, acf7, acf8, acf8x, acf8y, acf8z];      // (4)
            var approveAndOrder = [acf9, endContet];                                // (5)

            // function for hiding acf's
            function hide( array2hide ) {
              for (var i = 0; i < array2hide.length; i++) {
                $(array2hide[i]).css( "display", "none" );
              }
            }

            // function for showing acf's
            function display( array2show ) {
              for (var i = 0; i < array2show.length; i++) {
                $(array2show[i]).css( "display", "block" );
              }
            }

            // function for updating states - being returned via the module
            function updateWizardState ( state ) {
              switch(state) {
                case 1:
                hide(geneSelector);
                hide(geneArray);
                hide(imageSelector);
                hide(contantInfo);
                hide(approveAndOrder);
                display(aboutTheFarm);

                break;
                case 2:
                hide(aboutTheFarm);
                hide(imageSelector);
                hide(contantInfo);
                hide(approveAndOrder);
                display(geneSelector);
                display(geneArray);

                break;
                case 3:
                hide(aboutTheFarm);
                hide(geneSelector);
                hide(geneArray);
                hide(contantInfo);
                hide(approveAndOrder);
                display(imageSelector);

                break;
                case 4:
                hide(aboutTheFarm);
                hide(geneSelector);
                hide(geneArray);
                hide(imageSelector);
                hide(approveAndOrder);
                display(contantInfo);

                break;
                case 5:
                hide(aboutTheFarm);
                hide(geneSelector);
                hide(geneArray);
                hide(imageSelector);
                hide(contantInfo);
                display(approveAndOrder);

                break;
              }
            }

            // function for updating the navigation buttons state - being returned via the module
            function navPage ( state ) {
              if (state === 1) {
                $("#websiteNext").show();
                $("#websitePrev").hide();
              } else if (state === 5) {
                $("#websiteNext").hide();
                $("#websitePrev").show();
              } else {
                $("#websiteNext").show();
                $("#websitePrev").show();
              }
            }

            // always hide these elements
            $(hiddenImage).css( "display", "none" );
            $(acf5x1).css( "display", "none" );
            $(acfSubmit).css( "display", "none" );

            return {
              updateWizard  : updateWizardState,
              nav           : navPage
            };

          })();

          // initial state for the wizard
          var state = 1;
          acfController.updateWizard(state);
          acfController.nav(state);

          // go to the next state if next-button is pressed
          $("#websiteNext").click(function() {
            if (state < 5) {
              state++;
              acfController.nav(state);
            } else {
              return;
            }
              // console.log(state);
              acfController.updateWizard(state);
              
              $(window).scrollTop(0);
              $(".flowChange").removeClass("currentWebsiteNav");
              $("#"+state).addClass("currentWebsiteNav");
            });

          // go to the previous state if previous-button is pressed
          $("#websitePrev").click(function() {
            if (state > 1) {
              state--;
              acfController.nav(state);
            } else {
              return;
            }
              // console.log(state);
              acfController.updateWizard(state);
            });

          // control state with websiteNav
          $(".flowChange").click(function() {

            $(".flowChange").removeClass("currentWebsiteNav");
            $(this).addClass("currentWebsiteNav");

            var navState = $(this).attr('id');
            state = parseInt(navState);
            acfController.nav(state);
            acfController.updateWizard(state);
          });

          // ------------ image upload and preview functionality ------------------

          // hide the acf_form DOM-elements that displays the value of the image
          $(".acf-field-5a72ee562d7f4").find('.hide-if-value').hide();
          $(".acf-field-5a72ee562d7f4").find('.show-if-value').hide();

          // update the relevant DOM-elements if an image is already set (editing existing site)
          $(".acf-field-5a72ee562d7f4").find('.show-if-value').find('img').each(function() {
            var selectedImage = $(this).attr("src");
            if (selectedImage) {
              $('#previewImage').show();
              $('#previewImage').attr('src', selectedImage);
              $(".imageClearButton").show();
              $(".lineBreak").show();
            }
          });

          // costum button assigned with the upload functionality
          $('#uploadImageBtn').click(function() {
            $('#acf-field_5a72ee562d7f4').click();
          });

          // get the image from the input, show it and empty value for users-uploaded image - when the input changes
          $(".acf-field-5a72ee562d7f4").find(".acf-image-uploader input:file").change(function () {

            $(".danbredImage").removeClass("selectedImage");
            $("input[name='acf[field_5a7d658a5cda0]']").val('');

            var file = this.files[0];
            var reader = new FileReader();

            reader.onload = function (e) {
              $('#previewImage').show();
              $('#previewImage').attr('src', e.target.result);
              $(".imageClearButton").show();
              $(".lineBreak").show();
            };

            reader.readAsDataURL(file);
          });

          // clear all information about the uploaded image and hide the related elements
          $('.imageClearButton').click(function() {
            $('.imageClearButton').hide();
            $(".acf-field-5a72ee562d7f4").find('.acf-image-uploader').find('input').val("");
            $('#previewImage').hide();
            $('#previewImage').val("");
            $(".lineBreak").hide();
          });

          // select predefined images - clear the fields associated with a user-uploaded image
          $('.danbredImage').click(function() {

            $('.imageClearButton').hide();
            $(".acf-field-5a72ee562d7f4").find('.acf-image-uploader').find('input').val("");
            $('#previewImage').hide();
            $('#previewImage').val("");
            $(".lineBreak").hide();

            var imageSRC = $(this).find('img').attr('src');
            $("input[name='acf[field_5a7d658a5cda0]']").val(imageSRC);

            $(".danbredImage").removeClass("selectedImage");
            $(this).addClass("selectedImage");
          });

          // ------------ End of image upload and preview functionality ------------------

          // take control of the click event to submit website
          $('#websiteSubmit').click(function() {
            $('.acf-form-submit').find('input').click();
          });
        }
      }
    };


  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.


/* Float Label Pattern Plugin for Bootstrap 3.1.0 by Travis Wilson */  
(function ($) {
  $.fn.floatLabels = function (options) {

    // Settings
    var self = this;
    var settings = $.extend({}, options);


    // Actions
    var actions = {
      initialize: function() {
        self.each(function () {
          var $this = $(this);
          var $label = $this.children('label');
          var $field = $this.find('input,textarea').first();

          if ($this.children().first().is('label')) {
            $this.children().first().remove();
            $this.append($label);
          }

          var placeholderText = ($field.attr('placeholder') && $field.attr('placeholder') !== $label.text()) ? $field.attr('placeholder') : $label.text();

          $label.data('placeholder-text', placeholderText);
          $label.data('original-text', $label.text());

          if ($field.val() === '') {
            $field.addClass('empty');
          }
        });
      },
      swapLabels: function (field) {
        var $field = $(field);
        var $label = $(field).siblings('label').first();
        var isEmpty = Boolean($field.val());

        if (isEmpty) {
          $field.removeClass('empty');
          $label.text($label.data('original-text'));
        }
        else {
          $field.addClass('empty');
          $label.text($label.data('placeholder-text'));
        }
      }
    };

    // Event Handlers
    function registerEventHandlers() {
      self.on('input keyup change', 'input, textarea', function () {
        actions.swapLabels(this);
      });
    }


    // Initialization
    function init() {
      registerEventHandlers();

      actions.initialize();
      self.each(function () {
        actions.swapLabels($(this).find('input,textarea').first());
      });
    }
    init();


    return this;
  };

  $(function () {
    $('.float-label-control').floatLabels();
  });

  // menuhighligting fix
  $(function () {
    var pathname = location.href.match(/([^\/]*)\/*$/)[1];
    if (pathname === "edit-account") {
      $("#menu-item-14").removeClass("current-menu-item");
      $("#menu-item-54").addClass("current-menu-item");
    }
  });

})(jQuery);

